import React from 'react';
import Form from 'react-bootstrap/Form';

class SelectLabelGroup extends React.Component {

    render() {
        return (
            <Form.Group controlId={this.props.controlId} onChange={this.props.onChange}>
                <Form.Label>{this.props.label}</Form.Label>
                <Form.Control as="select" defaultValue={this.props.defaultValue}>
                    {this.props.children}
                </Form.Control>
            </Form.Group>
        )
    }
}

export default SelectLabelGroup;