import React, { useState, useEffect } from 'react';
import InputLabelGroup from 'InputLabelGroup';
import Form from 'react-bootstrap/Form';
import Label from 'react-bootstrap/FormLabel';
import { Col, Row } from 'react-bootstrap';

function calculateMortgagePayments(purchasePrice, interest, loanTerm) {
  // M = P x ir (1 + ir)^n / (1 + ir)^n - 1

  const ir = interest / 100 / 12;
  const n = loanTerm * 12;
  const M = purchasePrice * ir * Math.pow(1 + ir, n) / (Math.pow(1 + ir, n) - 1);

  return M;
}

function Buy(props) {

  const [interest, setInterest] = useState(6.25);
  const [stampDuty, setStampDuty] = useState(1500);
  const [conveyancingCost, setConveyancingCost] = useState(1500);
  const [buildingPestInspection, setBuildingPestInspection] = useState(1000);
  const [purchasePrice, setPurchasePrice] = useState(300000);
  const [loanTerm, setLoanTerm] = useState(30);
  const [insuranceBC, setInsuranceBC] = useState(2500);
  const [councilRates, setCouncilRates] = useState(1800);
  const [monthlyMortgagePayments, setMonthlyMortgagePayments] = useState(0);
  const [yourDeposit, setYourDeposit] = useState(100000);
  const [loanAmount, setLoanAmount] = useState(0);

  const handlePurchasePriceChange = (e) => setPurchasePrice(parseFloat(e.target.value) || 0);
  const handleStampDutyChange = (e) => setStampDuty(parseFloat(e.target.value) || 0);
  const handleConveyancingCostChange = (e) => setConveyancingCost(parseFloat(e.target.value) || 0);
  const handleBuildingPestInspectionChange = (e) => setBuildingPestInspection(parseFloat(e.target.value) || 0.00);
  const handleInterestChange = (e) => setInterest(parseFloat(e.target.value) || 0.00);
  const handleLoanTermChange = (e) => setLoanTerm(parseFloat(e.target.value) || 0);
  const handleInsuranceBCChange = (e) => setInsuranceBC(parseFloat(e.target.value) || 0);
  const handleCouncilRatesChange = (e) => setCouncilRates(parseFloat(e.target.value) || 0);
  const handleYourDepositChange = (e) => setYourDeposit(parseFloat(e.target.value) || 0);


  useEffect(() => {
    setLoanAmount(purchasePrice - yourDeposit);
    const monthlyMortgagePayments = calculateMortgagePayments(loanAmount, interest, loanTerm);
    setMonthlyMortgagePayments(monthlyMortgagePayments);
    const yearlyMortgagePayments = monthlyMortgagePayments * 12;
    props.onGrandTotalMonthlyChange(monthlyMortgagePayments + insuranceBC / 12 + councilRates / 12);
    props.onNoMortgageMonthlyChange(insuranceBC / 12 + councilRates / 12);

    let costOfMortgage = yearlyMortgagePayments * loanTerm
    let costOfInsurance = insuranceBC * props.years;
    let costOfCouncilRates = councilRates * props.years;
    let newBuyTotal = costOfMortgage + costOfInsurance + costOfCouncilRates + parseFloat(stampDuty) + parseFloat(conveyancingCost) + parseFloat(buildingPestInspection);
    props.onGrandTotalChange(newBuyTotal);
  }, [purchasePrice, interest, loanTerm, insuranceBC, councilRates, stampDuty, conveyancingCost, buildingPestInspection, loanAmount, props.years, props.onGrandTotalChange, props, yourDeposit]);

  return (
    <Form>
      <Row>
        <Col>
          <InputLabelGroup label='Your deposit' type="text" controlId="deposit" value={yourDeposit} onChange={handleYourDepositChange} />
        </Col>
        <Col>
          <InputLabelGroup label="Purchase Price" type="text" controlId="price" value={purchasePrice} onChange={handlePurchasePriceChange} />
        </Col>
      </Row>
      <InputLabelGroup label="Loan Amount (price - your deposit)" type="text" controlId="housePrice" value={loanAmount} readonly />
      <Row>
        <Col>
          <InputLabelGroup label="Loan Term (often 30 years)" type="text" controlId="loanTerm" defaultValue="30" onChange={handleLoanTermChange} /></Col>
        <Col>
          <InputLabelGroup label="Current interest rate of home loan:" type="text" controlId="interestRate" defaultValue={interest} onChange={handleInterestChange} />
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col><Label>Monthly Mortgage</Label></Col>
        <Col><Form.Control type="text" placeholder={monthlyMortgagePayments} readOnly /></Col>
      </Row>
      <fieldset>
        <legend>Upfront Costs</legend>
        <InputLabelGroup label="Stamp duty cost: (could be free for first home buyers)" type="text" controlId="stampDuty" value={stampDuty} onChange={handleStampDutyChange} />
        <InputLabelGroup label="Convenancing Cost:" type="text" controlId="convenancing" value={conveyancingCost} onChange={handleConveyancingCostChange} />
        <InputLabelGroup label="Building and Pest Inspection:" type="text" controlId="buildingInspection" value={buildingPestInspection} onChange={handleBuildingPestInspectionChange} />
      </fieldset>

      <fieldset>
        <legend>Ongoing Costs (yearly)</legend>
        <InputLabelGroup label="Building Insurance (or Body Corporate for apartment) and Contents:" type="text" controlId="insuranceBC" value={insuranceBC} onChange={handleInsuranceBCChange} />
        <InputLabelGroup label="Council Rates:" type="text" controlId="councilRates" value={councilRates} onChange={handleCouncilRatesChange} />
      </fieldset>
    </Form>
  );
}

export default Buy;