import React, { useState, useEffect } from 'react';
import InputLabelGroup from 'InputLabelGroup';
import SelectLabelGroup from 'SelectLabelGroup';
import Form from 'react-bootstrap/Form';
import Label from 'react-bootstrap/FormLabel';
import { Row, Col } from 'react-bootstrap';

function calculateMaxRent(initialRent, rentIncrease, years) {
    let yearlyRent = initialRent * 52;
    let result = yearlyRent * Math.pow(1 + rentIncrease / 100, years) / 52;
    console.log(result);
    return result;
}

function Rent(props) {

    const [rentIncrease, setRentIncrease] = useState(5);
    const [carpetCleaning, setCarpetCleaning] = useState(500);
    const [removalCosts, setRemovalCosts] = useState(500);
    const [rent, setRent] = useState(310);
    const [maxRent, setMaxRent] = useState(0.00);
    const [movingFreq, setMovingFreq] = useState(3);

    const handleRentChange = (event) => setRent(parseFloat(event.target.value));
    const handleRentIncrease = (event) => setRentIncrease(parseFloat(event.target.value));

    const handleCarpetCleaningChange = (event) => setCarpetCleaning(parseFloat(event.target.value));
    const handleRemovalCostsChange = (event) => setRemovalCosts(parseFloat(event.target.value));

    const handleMovingFreq = (event) => setMovingFreq(parseFloat(event.target.value));

    useEffect(() => {
        let costOfMovingHouse = (carpetCleaning + removalCosts) * props.years / movingFreq
        const totalRent = rent * 52 * Math.pow(props.years, (1 + rentIncrease / 100));
        let newRentTotalIncludingMoving = totalRent + costOfMovingHouse;
        let rentTotalWithoutMoving = totalRent
        setMaxRent(calculateMaxRent(rent, rentIncrease, props.years));
        props.onGrandTotalChange(newRentTotalIncludingMoving);
        const numberOfMonths = props.years * 12;
        props.onGrandTotalMonthlyChange(newRentTotalIncludingMoving / numberOfMonths);
        props.onGrandTotalMonthlyWithoutMovingChange(rentTotalWithoutMoving / numberOfMonths)
    }, [setMaxRent, props, rent, rentIncrease, carpetCleaning, removalCosts, movingFreq, props.years, props.onGrandTotalChange, props.onGrandTotalMonthlyChange, props.onGrandTotalMonthlyWithoutMovingChange]);

    return (
        <Form>
            <Row>
                <Col><InputLabelGroup label="Rent per week now" type="text" controlId="rentAmount" onChange={handleRentChange} defaultValue={rent} /></Col>
                {/* <Col><Label>Rent after {props.years} years of increases</Label>
                    <Form.Control type="text" placeholder={maxRent} readOnly={true} /></Col> */}
            </Row>
            <InputLabelGroup label="Annual rate of rent increase" type="text" controlId="increaseRate" defaultValue={rentIncrease} onChange={handleRentIncrease} />
            <Row>

            </Row>
            <hr />
            <h3>Costs of moving house</h3>
            <InputLabelGroup label="Carpet cleaning cost" type="text" controlId="carpetCleaning" defaultValue={carpetCleaning} onChange={handleCarpetCleaningChange} />
            <InputLabelGroup label="Removal cost" type="text" controlId="movalCost" defaultValue={removalCosts} onChange={handleRemovalCostsChange} />
            <SelectLabelGroup label="How often might your landlord not renew?" controlId="movingFreq" defaultValue={movingFreq} onChange={handleMovingFreq}>
                <option value="1">Once a year</option>
                <option value="3">Every three years</option>
                <option value="5">Every five years</option>
                <option value="10">Every 10 years</option>
            </SelectLabelGroup>
            <div>Total: {props.total.toLocaleString({ style: 'currency' })}</div>
        </Form>

    );
}

export default Rent;